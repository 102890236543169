@use 'styles/abstracts' as *;

.container {
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  display: grid;
  row-gap: 0.5rem;
  @include breakpoint(tab-land) {
    div:nth-child(1) {
      display: none;
    }
    div:nth-child(2) {
      display: none;
    }
    div:nth-child(3) {
      position: fixed;
      bottom: 0.75rem;
      right: 0.75rem;
    }
  }
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

