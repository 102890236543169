@use 'styles/abstracts' as *;

span.root {
  width: 2.25rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  padding: 0;
  & > span.switchBase:global(.Mui-checked) {
    background-color: $col-primary-600;
  }
}
span.switchBase {
  transform: translateX(calc(-7px)) translateY(-8px);
  &:global(.Mui-checked) {
    transform: translateX(calc(7px)) translateY(-8px);
    & + .track {
      background-color: $col-primary-600;
      opacity: 1;
    }
    & .thumb {
      background-color: '#fff';
    }
  }
}
span.thumb {
  background-color: $col-white;
  height: 18px;
  width: 18px;
}
