@use 'styles/abstracts' as *;

.container {
  display: grid;
  grid-template-columns: auto;
  &.drawingMode {
    grid-template-columns: auto auto;
    &.notDrawing {
      column-gap: 0.75rem;
    }
  }
  justify-content: center;
  width: fit-content;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 4.5rem;
  border-radius: 0.25rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
  @include breakpoint(tab-land) {
    top: 3.25rem;
    right: 0.75rem;
    left: auto;
    transform: translate(0);
  }
}

.iconButtonText {
  display: grid;
  padding: 0.5rem;
  column-gap: 0.5rem;
  grid-template-columns: auto max-content;
  border-radius: 0.25rem;
  align-items: center;
  color: $col-white;
  background-color: $col-primary-600;
  &.light {
    color: $col-primary-600;
    background-color: $col-white;
  }
  cursor: pointer;
}

.buttonGroup {
  display: grid;
  padding: 0.25rem;
  border-radius: 0.25rem;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.25rem;
  background-color: $col-white;
  align-items: center;
}

