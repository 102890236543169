@use 'styles/abstracts' as *;

.button {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.disabled {
  cursor: default;
  svg {
    path {
      fill: $col-gray-500;
    }
  }
}
