@use '../abstracts' as *;

.ol-scale-bar {
  left: unset !important;
  bottom: 1.6rem !important;
  right: 1.25rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
  @include breakpoint(tab-land) {
    display: none;
  }
  .ol-scale-bar-inner {
    background-color: $col-white;
    border-radius: 4px;
    width: fit-content !important;
    padding: 0 0.625rem;
    display: grid;
    div:nth-child(1) {
      grid-column: 1/3;
      justify-self: center;
      width: unset;
      position: unset;
    }
    div:nth-child(2) {
      grid-row: 2;
      grid-column: 1/2;
      position: unset !important;
    }
    div:nth-child(3) {
      grid-row: 2;
      grid-column: 1/2;
    }
    div:nth-child(5) {
      justify-self: end;
      margin-left: unset !important;
      margin-right: -0.5rem;
      min-width: unset !important;
      left: unset !important;
    }
    div:nth-child(3),
    div:nth-child(5) {
      padding-bottom: 0.25rem;
    }
    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(4) {
      .ol-scale-step-marker {
        position: relative !important;
      }
    }
  }
  & * {
    color: $col-gray-700 !important;
  }
  .ol-scale-text {
    width: fit-content !important;
  }
  .ol-scale-singlebar {
    border: 1px solid $col-primary-600;
    width: 75px !important;
  }
  .ol-scale-step-marker {
    background-color: $col-primary-600;
  }
  .ol-scale-singlebar.ol-scale-singlebar-even {
    background-color: $col-primary-700;
  }
}

