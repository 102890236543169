@use 'styles/abstracts' as *;

.container {
  display: block;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;
  background: $col-white;
  border-radius: 0.25rem;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
  .model {
    display: none;
  }
  &:hover {
    z-index: 1;
    display: grid;
    gap: 0.5rem;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: column;
    height: auto;
    width: auto;
    .model {
      display: grid;
      height: 1.3125rem;
      grid-template-columns: 1rem 1fr;
      gap: 0.5rem;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .icon {
      display: none;
    }
  }
  @include breakpoint(tab-land) {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    height: auto;
    width: auto;
    padding: 1.5rem 0 0 1.125rem;
    box-shadow: none;
    pointer-events: none;
    padding-bottom: 3.5rem;
    .model {
      display: grid;
      height: 1.3125rem;
      grid-template-columns: 1rem 1fr;
      gap: 0.5rem;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .icon {
      display: none;
    }
  }
}
