@use 'styles/abstracts' as *;

.leftToolbar {
  position: absolute;
  top: 2.5rem;
  left: 0;
  margin: 2rem 1rem 2rem 1rem;
  display: grid;
  grid-template-rows: min-content auto;
  @include breakpoint(tab-land) {
    top: 4.5rem;
    left: -0.25rem;
  }
}

.openLeftToolBarMobile {
  @include breakpoint(tab-land) {
    display: none;
  }
}

.mainTools {
  display: grid;
  height: fit-content;
  width: 20rem;
  gap: 0.5rem;
  @include breakpoint(tab-land) {
    display: none;
  }
}

.tileSelector {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  align-items: end;
  column-gap: 0.5rem;
  @include breakpoint(tab-land) {
    display: none;
  }
}
.mobileMenuContainer {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  background-color: white;
  border: 1px solid $col-primary-600;
  border-radius: 0.25rem;
  position: fixed;
  top: 3.25rem;
  left: 0.75rem;
  @include breakpoint(tab-land) {
    display: flex;
    z-index: 10;
  }
}

.mainToolsButtons {
  display: none;
  flex-direction: column;
  @include breakpoint(tab-land) {
    display: flex;
  }
  .searchButton,
  .layerButton,
  .downloadButton,
  .mapStyleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: $col-primary-600;
    padding: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  .searchButton {
    border-top: 1px solid $col-gray-500;
  }
}
.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: $col-primary-600;
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
.closeButton {
  z-index: 20;
  display: none;
  align-items: center;
  gap: 0.5rem;
  position: fixed;
  top: 3.25rem;
  left: 0.75rem;
  color: $col-primary-600;
  @include breakpoint(tab-land) {
    display: flex;
    background-color: $col-white;
    top: 2.5rem;
    left: 0rem;
    padding: 0.75rem 0 0 0.75rem;
    width: 100%;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
  }
  b {
    font-size: 1.25rem;
  }
}

.showAreaButton {
  z-index: 20;
  display: none;
  align-items: center;
  gap: 0.5rem;
  position: fixed;
  bottom: 0.75rem;
  right: 0.75rem;
  background-color: $col-primary-600;
  color: white;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    svg {
      path {
        fill: white;
      }
    }
  }
  @include breakpoint(tab-land) {
    display: flex;
  }
}
.legendButton {
  width: 2.5rem;
  height: 2.5rem;
  display: none;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  background-color: white;
  border-radius: 0.25rem;
  position: fixed;
  bottom: 0.75rem;
  left: 0.75rem;
  @include breakpoint(tab-land) {
    display: flex;
  }
}

