@use 'styles/abstracts' as *;

div.inputRoot {
  height: 2.25rem;
}

.disabled {
  label {
    color: $col-gray-400 !important;
  }
  div:global(.MuiInputBase-adornedEnd) {
    background-color: $col-gray-100;
    color: $col-gray-400;
  }
}

.loadingIcon {
  margin-top: 0.25rem;
  margin-right: 0.25rem;
}
