@use 'styles/abstracts' as *;

.root {
  border-radius: 0 !important;
  border: none !important;

  :global(.MuiDataGrid-footerContainer) {
    min-height: 2.25rem !important;
    max-height: 2.25rem !important;
    :global(.MuiTablePagination-root) {
      :global(.MuiTablePagination-toolbar) {
        color: $col-gray-700;
        :global(.MuiTablePagination-actions) {
          svg {
            height: 1rem;
          }
          button:disabled svg {
            fill: $col-gray-500;
          }
          button:hover svg {
            fill: $col-primary-400;
          }
          svg {
            fill: $col-primary-600;
          }
        }
        min-height: 2.25rem !important;
        max-height: 2.25rem !important;
      }
    }
  }

  :global(.MuiDataGrid-columnHeaders) {
    max-height: 2rem !important;
    min-height: 2rem !important;
  }

  :global(.MuiDataGrid-columnSeparator) {
    display: none !important;
    width: 0;
    height: 0;
  }

  :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar {
    width: 1rem;
  }

  :global(
      .MuiDataGrid-virtualScroller
    )::-webkit-scrollbar:horizontal {
    display: none;
  }

  :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar-track,
  :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar-thumb {
    height: 0.375rem;
    border: 0.3125rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0.5rem;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar-track {
    background-color: $col-gray-300;
  }

  :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar-thumb {
    background-color: $col-gray-600;
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 5rem;
  height: 100%;
  padding: 0.25rem;
}

.spinner {
  display: flex;
  background-color: $col-white;
  min-height: 5rem;
  height: 100%;
  width: calc(100% - 2px);
  justify-content: center;
  align-items: center;
  @include breakpoint(tab-land) {
    width: 100dvw;
    padding: 0 0 2.5rem;
  }
}

