@use 'styles/abstracts' as *;
@use 'styles/layout' as *;

@mixin full-screen-container {
  top: $mobile-navbar-height;
  left: 0;
  width: 100dvw;
  height: 100%;
  z-index: 21;
  border: none;
  max-height: none;
}

@mixin full-screen-header {
  padding: 0.5rem 0 0 0;
  width: 100dvw;
}

@mixin full-screen-titlebar {
  justify-content: flex-end;
  gap: 0.5rem;
  margin-left: 0.75rem;
  margin-right: 0.5rem;
}

@mixin full-screen-titlebar-span {
  color: $col-primary-600;
  font-weight: 600;
  font-size: 1.25rem;
}

@mixin full-screen-close {
  width: 2.5rem;
  height: 2.5rem;
  svg {
    width: 1.25rem;
  }
}

@mixin full-screen-heading {
  transform: translateY(0);
  margin: 0 0 0 0.75rem;
  p {
    font-size: 16px;
  }
  span {
    color: $col-gray-600;
  }
}

@mixin full-screen-selection {
  width: 100%;
  z-index: 20;
  gap: 0;
  .models {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $col-gray-600;
    .modelButton {
      border-radius: 0.25rem;
    }
    .modelTitles {
      display: block;
    }
    .alternativeModelTitles {
      display: none;
    }
  }
  .entities {
    border: none;
    .entityButton {
      border-radius: 0.25rem;
    }
  }
}

@mixin full-screen-table {
  width: 100dvw;
  padding: 0 0 2.5rem;
}

.container {
  $this: &;
  position: absolute;
  top: calc(3.5rem + 7.5rem);
  right: 1rem;
  width: 32rem;
  height: 40rem;
  display: grid;
  grid-template-columns: fit-content;
  grid-template-rows: 9.375rem calc(100% - 9.375rem);
  padding-bottom: 0.25rem;
  &.fullscreen {
    @include full-screen-container;
    top: $navbar-height;
    height: calc(100% - $navbar-height/3);
  }
  @include breakpoint(tab-land) {
    top: $mobile-navbar-height !important;
    height: 100% !important;
    @include full-screen-container;
  }
  .header {
    user-select: none;
    width: 32rem;
    display: flex;
    padding: 0.5rem 0.5rem 0 0.75rem;
    flex-direction: column;
    &.fullscreen {
      @include full-screen-header;
    }
    @include breakpoint(tab-land) {
      @include full-screen-header;
    }
    .titleBar {
      display: grid;
      grid-template-columns: 1fr auto 2rem;
      align-items: center;
      &.fullscreen {
        @include full-screen-titlebar;
        span {
          @include full-screen-titlebar-span;
        }
      }
      @include breakpoint(tab-land) {
        @include full-screen-titlebar;
      }
      span {
        color: $col-gray-600;
        @include breakpoint(tab-land) {
          @include full-screen-titlebar-span;
        }
      }
      .enlarge {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 1.25rem;
        }
        @include breakpoint(tab-land) {
          cursor: unset;
          width: 0px;
          svg {
            width: 0px;
          }
        }
      }
      .close {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 1.25rem;
        }
        @include breakpoint(tab-land) {
          @include full-screen-close;
        }
      }
    }
    .heading {
      transform: translateY(-0.25rem);
      &.fullscreen {
        @include full-screen-heading;
      }
      @include breakpoint(tab-land) {
        @include full-screen-heading;
      }
    }
    .selection {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-right: 0.5rem;
      .models {
        .modelTitles {
          display: block;
        }
        .alternativeModelTitles {
          display: none;
        }
      }
      .models {
        .modelButton {
          cursor: pointer;
        }
      }
      .entities {
        .entityButton {
          cursor: pointer;
        }
      }
      &.fullscreen {
        @include full-screen-selection;
      }
      @include breakpoint(tab-land) {
        @include full-screen-selection;
      }
      @include breakpoint(phone) {
        .models {
          .modelTitles {
            display: none;
          }
          .alternativeModelTitles {
            display: block;
          }
        }
      }
      & > * {
        height: 2.5rem;
        border-radius: 0.5rem;
        padding: 0.25rem;
        border: 1px solid $col-gray-400;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        text-align: center;
        .selected {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          border-radius: 0.25rem;
          background-color: $col-primary-600;
          color: $col-white;
        }
      }
    }
  }
  .table {
    width: 32rem;
    padding-top: 1.25rem;
    &.fullscreen {
      @include full-screen-table;
    }
    @include breakpoint(tab-land) {
      @include full-screen-table;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  b {
    display: none;
  }
  @include breakpoint(tab-land) {
    background-color: $col-primary-600;
    border-radius: 0.25rem;
    b {
      display: flex;
      align-items: center;
      padding: 0 0.7rem;
      color: $col-white;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  b {
    display: none;
  }
  @include breakpoint(tab-land) {
    padding: 0 0.7rem;
    height: auto;
    b {
      display: flex;
      align-items: center;
      padding-left: 0.7rem;
      color: $col-primary-600;
      background-color: $col-white;
      height: 2.5rem;
    }
  }
}

