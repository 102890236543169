@use 'styles/abstracts' as *;

.body {
  padding: 0.5rem;
  @include breakpoint(tab-land) {
    padding: 0.75rem;
  }
}

.separator {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: $col-gray-600;
  text-align: center;
  span {
    padding: 0 0.5rem;
  }
  &::before,
  &::after {
    content: '';
    height: 1px;
    flex-grow: 1;
    background-color: $col-gray-600;
  }
}

.selectedArea {
  padding: 0.5rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: min-content auto;
  gap: 0.5rem;
  color: $col-gray-600;
  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.downloadWarning {
  margin: 0 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid $col-gray-600;
  color: $col-gray-600;
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 0.5rem;
}

.toggles {
  display: grid;
  gap: 0.44rem;
  padding: 0.5rem;
  .header {
    color: $col-primary-600;
  }
}

.toggle {
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  column-gap: 0.5rem;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  @include breakpoint(tab-land) {
    flex-direction: row;
    gap: 2.5rem;
    padding-bottom: 5rem;
  }
}

.downloadButtonContainer {
  display: grid;
  padding: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  @include breakpoint(tab-land) {
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

.downloadButton {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: min-content auto;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background: var(--G100, #eaf4f7);
  align-items: center;
  justify-content: center;
  color: $col-gray-500;
  pointer-events: none;
  cursor: pointer;
}

.enabled {
  background: var(--P600, #0050de);
  color: white;
  pointer-events: all;
  svg {
    path {
      fill: white;
    }
  }
}

