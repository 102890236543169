@use 'styles/abstracts' as *;

.header {
  padding: 0.5rem;
  color: $col-primary-600;
  display: grid;
  align-items: center;
  grid-template-columns: min-content min-content auto;
  gap: 0.5rem;
  @include breakpoint(tab-land) {
    padding: 0.75rem;
  }
}

.toggle {
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  column-gap: 0.5rem;
  margin-left: 0.75rem;
  margin-bottom: 0.5rem;
}
