@mixin truncate($width) {
  width: $width;
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin breakpoint($point) {
  @if $point == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
  @if $point == tab-land {
    @media only screen and (max-width: 87.5em) {
      @content;
    }
  }
  @if $point == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }
  @if $point == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }
}

