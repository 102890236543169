@use '../abstracts' as *;

.text-input {
  display: flex;
  flex-direction: column;
  label {
    color: $col-primary-600;
    span {
      color: $col-gray-500;
    }
  }
  div.MuiOutlinedInput-root {
    padding: 0px;
  }
  input[type='search']::-webkit-search-cancel-button {
    appearance: none;
  }
  input[type='text'] {
    border-radius: 0.25rem;
    border: 1px solid $col-gray-500;
    &:focus-visible {
      outline-color: $col-primary-600;
    }
  }
}
