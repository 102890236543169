@use 'styles/abstracts' as *;
@use 'styles/layout' as *;

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid rgba(180, 180, 180, 0.6);
  border-top-color: rgba($col-primary-700, 0.6);
  animation: spinner 0.6s linear infinite;
}

.loadingCursor {
  cursor: progress;
}

.map {
  width: 100dvw;
  height: calc(100dvh - $navbar-height);
  @include breakpoint(tab-land) {
    height: calc(100dvh - $mobile-navbar-height);
  }
}

