@use 'styles/abstracts' as *;

button {
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
  outline: none;
  transition: all 0.3s ease;
  font-family: $titillium;
  font-size: 1rem;
  overflow: hidden;
  &.primary {
    color: $col-white;
    background-color: $col-primary-600;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
    svg {
      fill: $col-white;
    }
    &:hover {
      background-color: $col-primary-500;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.12);
    }
    &:active {
      background-color: $col-primary-700;
      box-shadow: none;
    }
    &:disabled {
      color: $col-gray-500;
      background-color: $col-gray-100;
      box-shadow: none;
      svg {
        fill: $col-gray-500;
      }
    }
  }
  &.secondary {
    color: $col-primary-600;
    background-color: $col-white;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
    svg {
      fill: $col-primary-600;
    }
    &:hover {
      background-color: $col-gray-100;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.12);
    }
    &:active {
      background-color: $col-gray-200;
      box-shadow: none;
    }
    &:disabled {
      color: $col-gray-500;
      background-color: $col-gray-100;
      box-shadow: none;
      svg {
        fill: $col-gray-500;
      }
    }
  }
  & {
    color: $col-white;
    background-color: transparent;
    svg {
      fill: $col-primary-600;
    }
    &:hover {
      background-color: $col-primary-400;
      svg {
        fill: $col-primary-400;
      }
    }
    &:active {
      color: $col-primary-700;
      background-color: $col-gray-200;
      svg {
        fill: $col-primary-700;
      }
    }
    &:disabled {
      color: $col-gray-500;
      svg {
        fill: $col-gray-500;
      }
    }
  }
}
