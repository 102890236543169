@use 'styles/abstracts' as *;

.rootContainer {
  border-radius: 0.75rem;
  form {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    color: $col-primary-600;
    gap: 2rem;
  }
}

.formContainer {
  display: flex;
  width: 16rem;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.resetPasswordHeader {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  a {
    display: flex;
    align-items: center;
  }
}

.inputBox {
  display: flex;
  flex-direction: column;
}

.outlinedInput {
  height: 2.25rem;
  input {
    background-clip: text !important;
  }
  .eye {
    cursor: pointer;
  }
}

.forgotPasswordLabel {
  display: flex;
  flex-direction: column;
  color: #909ca0;
  a {
    font-weight: 600;
    color: $col-primary-600;
  }
}

.actionButton {
  cursor: pointer;
  width: 100%;
  border-radius: 0.25rem;
  background-color: $col-primary-600;
  color: $col-white;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  &:disabled {
    background-color: $col-gray-100;
    color: $col-gray-500;
  }
}

.pattern {
  display: flex;
  flex-direction: column;
  text-justify: center;
  span {
    color: $col-gray-700;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .check {
    color: #06b460;
  }
  .error {
    color: #f44545;
  }
}

