$col-primary-100: #bfecfc;
$col-primary-200: #85ddfd;
$col-primary-300: #44bcff;
$col-primary-400: #2898fd;
$col-primary-500: #176afd;
$col-primary-600: #0050de;
$col-primary-700: #0023b5;
$col-primary-800: #001983;
$col-primary-900: #000f4e;
$col-secondary-100: #fff4ae;
$col-secondary-200: #ffee84;
$col-secondary-300: #ffe650;
$col-secondary-400: #fad90f;
$col-secondary-500: #f5c10a;
$col-secondary-600: #faaf19;
$col-secondary-700: #ec9d00;
$col-secondary-800: #d89000;
$col-secondary-900: #ac7300;
$col-white: #ffffff;
$col-gray-100: #eaf4f7;
$col-gray-200: #e0ecef;
$col-gray-300: #c8d4d8;
$col-gray-400: #c8d4d8;
$col-gray-500: #acb9bd;
$col-gray-600: #909ca0;
$col-gray-700: #5f686a;
$col-gray-800: #353839;
$col-gray-900: #1c1f20;
$col-alert-dark: #b10404;
$col-alert-mid: #e21212;
$col-alert-light: #f44545;
$col-confirm-dark: #058949;
$col-confirm-mid: #06b460;
$col-confirm-light: #34de8c;
$col-diretto: #007ea6;
$col-concessione: #5d00ba;
$col-fwa: #d78f00;
