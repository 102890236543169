@use 'styles/abstracts' as *;
@use 'styles/layout' as *;

.navbar {
  padding: 0 1rem;
  height: $navbar-height;
  background-color: $col-primary-600;
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  @include breakpoint(tab-land) {
    height: $mobile-navbar-height;
    padding: 0 0 0 0.625rem;
  }
  .logo {
    display: grid;
    align-items: center;
    svg {
      height: 3.25rem;
      @include breakpoint(tab-land) {
        height: 1.5rem;
      }
    }
  }

  .toHome {
    justify-self: end;
    width: 5.75rem;
    height: 2.5rem;
    display: grid;
    grid-template-columns: auto i;
    justify-content: center;
    gap: 0.82rem;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid $col-white;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
    color: $col-white;
    cursor: pointer;
    @include breakpoint(tab-land) {
      display: flex;
      height: 1.75rem;
      width: fit-content;
      padding: 0.625rem;
      font-size: 12px;
      margin-right: 16px;
    }
  }

  .login {
    justify-self: end;
    width: 5.75rem;
    height: 2.5rem;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 0.82rem;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid $col-white;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
    color: $col-white;
    cursor: pointer;
    @include breakpoint(tab-land) {
      display: flex;
      width: fit-content;
      padding: 0.625rem;
      border: none;
      box-shadow: none;
      b {
        display: none;
      }
    }
  }
}

