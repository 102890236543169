@use '../abstracts/' as *;

body {
  font-family: $titillium;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3125rem;
  b {
    font-weight: 600;
  }
}

p {
  font-family: $titillium;
  font-size: 1.25rem;
  font-weight: 400;
  b {
    font-weight: 600;
  }
}

h1 {
  font-family: $titillium;
  font-size: 2.25rem;
  font-weight: 600;
  text-transform: uppercase;
}

h2 {
  font-family: $titillium;
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

h3 {
  font-family: $titillium;
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;
}

h4 {
  font-family: $titillium;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: -0.04688rem;
  text-transform: uppercase;
}
