@use 'styles/abstracts' as *;

.container {
  width: 12rem;
  border-radius: 0.75rem;
  border: 1px solid $col-primary-600;
  background-color: $col-white;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
  b {
    color: $col-primary-600;
  }
  @include breakpoint(tab-land) {
    display: none;
  }
  .selection,
  .options {
    display: grid;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    cursor: pointer;
    .selected {
      color: $col-gray-500;
    }
  }
  .selection {
    height: 2.5rem;
    grid-template-columns: auto min-content;
    svg {
      transition: all 0.25s;
    }
  }
}

.mobileContainer {
  display: none;
  flex-direction: column;
  @include breakpoint(tab-land) {
    display: flex;
  }
  .options {
    margin-top: 1.25rem;
    div {
      height: 2.5rem;
      display: flex;
      align-items: center;
      padding: 0 0.9375rem 0 0.75rem;
      justify-content: space-between;
    }
    div:not(:first-child) {
      border-top: 1px solid $col-gray-300;
    }
    .selected {
      color: $col-primary-600;
      svg {
        width: 0.875rem;
        height: 0.875rem;
      }
    }
    .notSelected {
      color: $col-gray-500;
    }
  }
}

