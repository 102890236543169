@use 'styles/abstracts' as *;

.round {
  border-radius: 0.75rem;
}

.header {
  min-height: 2.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1rem;
  align-items: center;
  justify-content: space-between;
  .iconText {
    display: grid;
    grid-template-columns: 1.625rem 1fr;
    column-gap: 0.5rem;
    color: $col-primary-600;
    align-items: center;
    justify-content: center;
    svg {
      height: 1.375rem;
    }
  }
}

.footer {
  display: flex;
}

.downloadButtonContainer {
  display: grid;
  padding: 0.5rem;
  align-items: center;
  justify-content: end;
}

.downloadButton {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: min-content auto;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background: var(--G100, #eaf4f7);
  align-items: center;
  justify-content: center;
  color: $col-gray-500;
  pointer-events: none;
  cursor: pointer;
}

.enabled {
  background: var(--P600, #0050de);
  color: white;
  pointer-events: all;
  svg {
    path {
      fill: white;
    }
  }
}

.close {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 0.625rem;
  }
}
