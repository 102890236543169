@use 'styles/abstracts' as *;

span.root {
  height: 1.1875rem;
  width: 1.1875rem;
  border-radius: 4.5px;
  border: 1px;
  padding: 0;
  &:global(.Mui-checked) {
    color: $col-primary-600;
  }
}

span.disabled {
  svg {
    rect {
      stroke: $col-gray-300;
    }
  }
  &:global(.Mui-checked) {
    color: $col-gray-200;
  }
}
