@use 'styles/abstracts' as *;
@use 'styles/layout' as *;

.container {
  width: 100%;
  height: calc(100dvh - $navbar-height);
  @include breakpoint(tab-land) {
    height: calc(100dvh - $mobile-navbar-height);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../../public/login.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

