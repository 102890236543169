@use 'styles/abstracts' as *;

.toolsPageMobile {
  display: none;
  position: absolute;
  top: 2.5rem;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow-y: scroll;
  @include breakpoint(tab-land) {
    display: flex;
  }
  .toolWrapper {
    position: relative;
    top: 3rem;
    width: 100%;
    height: calc(100% - 5rem);
    hr:first-of-type {
      display: none;
    }
    .layerSelectWrapper {
      padding-bottom: 3rem;
    }
  }
  .toolWrapper:last-child {
    padding-bottom: 5rem;
  }
}

