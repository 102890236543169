@use 'styles/abstracts' as *;
@use 'styles/layout' as *;

.rootContainer {
  height: calc(100dvh - $navbar-height);
  @include breakpoint(tab-land) {
    height: calc(100dvh - $mobile-navbar-height);
  }
  display: grid;
  grid-template-rows: 1fr auto 1fr;
}

.errors {
  display: flex;
  justify-content: center;
  align-items: center;
}

