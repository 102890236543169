@use 'styles/abstracts' as *;

.tooltip {
  border-radius: 0.75rem;
  background: $col-white;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
  color: $col-gray-900;
}

span.arrow {
  color: $col-white;
}
