@use 'styles/abstracts' as *;

.root {
  height: 2.5rem;
  padding: 0 0.25rem 0 1rem;
  border-radius: 0.25rem;
  background-color: $col-primary-400;
  color: $col-white;
  box-shadow: 0.25rem 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 0.25rem;
  fill: $col-white;
  white-space: nowrap;
  & > * {
    display: flex;
    align-items: center;
  }
}

.noClose {
  display: flex;
  padding: 0 0.75rem 0 0.75rem;
  justify-content: center;
}

.alert {
  background-color: $col-white;
  color: $col-alert-dark;
  .close {
    path {
      fill: $col-alert-dark;
    }
  }
}

.close {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 0.625rem;
  }
  path {
    fill: $col-white;
  }
}

